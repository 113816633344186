import type * as documentDataApiTypes from 'shared/features/documentData/documentData.apiV2.types';
import type * as commandsApiTypes from 'shared/features/commands/commands.api.types';

import type { BulkReprocessModalRule } from './useBulkReprocessModalData.types';

import { COMMAND_REQUEST_NAMES } from 'admin/constants/commandRequestTypes';

import * as constantsDocuments from 'shared/features/documentData/documentData.constants';

export const convertModalRulesToApiActions = ({
  rules
}: {
  rules: BulkReprocessModalRule[];
}): documentDataApiTypes.DocumentsBulkUpdatePayloadAction[] => {
  return rules.flatMap(rule => {
    return [
      {
        action: constantsDocuments.DocumentsBulkUpdateActions.EDIT,
        pipelineStatus: rule?.action?.toLowerCase(),
        bulkAction: rule?.bulkAction?.toLowerCase()
      } as documentDataApiTypes.DocumentsBulkUpdatePayloadAction
    ];

    return [];
  });
};

export const getBulkUpdateCommandId = ({
  commands
}: {
  commands?: commandsApiTypes.CommandRequestFromResponse[];
}) => {
  return commands?.find(command => command.name === COMMAND_REQUEST_NAMES.BULK_DOCUMENT_UPDATE)?.id;
};
