import type {
  BulkReprocessModalData,
  BulkReprocessModalRule
} from './useBulkReprocessModalData.types';

export const BULK_REPROCESS_MODAL_EMPTY_RULE: Omit<BulkReprocessModalRule, 'id'> = {
  action: null,
  bulkAction: null
};

export const BULK_REPROCESS_MODAL_INITIAL_RULES: BulkReprocessModalData['localState']['rules'] = [
  {
    id: 0,
    ...BULK_REPROCESS_MODAL_EMPTY_RULE
  }
];
