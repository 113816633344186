import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from 'lodash';
import { Alert } from "react-bootstrap";
import TextInput from "../../../components/TextInput";
import { DOC_RELATION_DETAILS, DOC_RELATION_GENERAL } from "./DocumentRelations.constants";
import Button from "../../../components/Button";
import AsyncSelect from "../../../components/ReactSelect/SelectAsync";
import { formDocumentRelationParameters, loadLanguages } from "./DocumentRelations.helper";
import { createDocumentRelations } from "../../../../shared/features/documentRelations/documentRelations.actions";


const DocumentRelationsCreation = ({
  toggleEditMode,
  current_user,
  docDetailsInfo
}) => {
  const dispatch = useDispatch();
  const [docRelationDetails, setDocRelationDetails] = useState(DOC_RELATION_DETAILS);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [docRelationDetails]);

  const handleChanges = (name, value) => {
    setDocRelationDetails({
      ...docRelationDetails,
      [name]: value
    });
  };

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  useEffect(() => handleChanges(DOC_RELATION_GENERAL.id, _.get(docDetailsInfo, 'id', 0)),
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [docDetailsInfo]);

  const createDocRelation = () => {
    if (docRelationDetails[DOC_RELATION_GENERAL.related_doc_id]
      && docRelationDetails[DOC_RELATION_GENERAL.language]) {
      const docRelationInfo = formDocumentRelationParameters(docRelationDetails, current_user);
      dispatch(createDocumentRelations(docRelationInfo));
      toggleEditMode();
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <div className="document-list_details_items_list">
        <TextInput
          className="input_4"
          label="Related Document ID"
          name={DOC_RELATION_GENERAL.related_doc_id}
          value={docRelationDetails[DOC_RELATION_GENERAL.related_doc_id]}
          onChange={handleChangesEvent}
        />
        <AsyncSelect
          className="input_4"
          label="Language"
          value={docRelationDetails[DOC_RELATION_GENERAL.language]}
          onChange={(e) => handleChanges(DOC_RELATION_GENERAL.language, e)}
          loadOptions={
            value => loadLanguages('name', value, 'name')
          }
          isClearable
        />
        {error && (
          <Alert bsStyle="danger" className="adminToolErrorMessage">
            Please check your entries
          </Alert>
        )}
      </div>
      <Button
        size="custom"
        color="success"
        onClick={() => createDocRelation()}
      >
        Save Changes
      </Button>
    </div>
  );
};


DocumentRelationsCreation.propTypes = {
  toggleEditMode: PropTypes.func.isRequired,
  current_user: PropTypes.shape({}).isRequired,
  docDetailsInfo: PropTypes.shape({}).isRequired
};

export default DocumentRelationsCreation;
