import {
  DOCUMENT_PIPELINE_STATUSES,
  DOCUMENT_BULK_ACTIONS
} from 'admin/constants/docPipelineStatuses';

export const BULK_REPROCESS_ACTIONS_OPTIONS = [
  {
    label: 'Reindex',
    value: DOCUMENT_PIPELINE_STATUSES.CONCEPT_TAGGED.type,
    bulkAction: DOCUMENT_BULK_ACTIONS.REINDEX.type
  },
  {
    label: 'Rescrape',
    value: DOCUMENT_PIPELINE_STATUSES.RESCRAPED.type,
    bulkAction: DOCUMENT_BULK_ACTIONS.RESCRAPE.type
  },
  {
    label: 'Translate',
    value: DOCUMENT_PIPELINE_STATUSES.LANGUAGE_DETECTED.type,
    bulkAction: DOCUMENT_BULK_ACTIONS.TRANSLATE.type
  },
  {
    label: 'Sentencify',
    value: DOCUMENT_PIPELINE_STATUSES.TERM_SAMPLED.type,
    bulkAction: DOCUMENT_BULK_ACTIONS.SENTENCIFY.type
  },
  {
    label: 'Versionize',
    value: DOCUMENT_PIPELINE_STATUSES.ORIGINAL.type,
    bulkAction: DOCUMENT_BULK_ACTIONS.VERSIONIZE.type
  }
];
