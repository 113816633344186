import type { BulkReprocessModalProps } from '../BulkReprocessModal.types';
import type { BulkReprocessModalData } from './useBulkReprocessModalData.types';
import type { BulkReprocessModalFormattedData } from './useBulkReprocessModalFormattedData.types';
import type { useBulkReprocessModalQueries } from './useBulkReprocessModalQueries';

import * as helpers from './useBulkReprocessModalFormattedData.helpers';

import pluralize from 'pluralize';

import { useMemo } from 'react';
import { useBulkReprocessModalMutations } from './useBulkReprocessModalMutations';

export const useBulkReprocessModalFormattedData = ({
  props,
  localState,
  queries,
  mutations
}: {
  props: Pick<BulkReprocessModalProps, 'selectedDocumentIds'>;
  localState: BulkReprocessModalData['localState'];
  queries: ReturnType<typeof useBulkReprocessModalQueries>;
  mutations: ReturnType<typeof useBulkReprocessModalMutations>;
}): BulkReprocessModalFormattedData => {
  return useMemo(() => {
    const isUpdating = mutations.updateDocumentDataMutation.isLoading;

    return {
      modalTitle: `Bulk Reprocess ${props.selectedDocumentIds.length} ${pluralize(
        'document',
        props.selectedDocumentIds.length
      )}`,
      isRulesListShown: Boolean(localState.rules.length),
      areSelectedDocumentLabelsLoaded: Boolean(queries.getSelectedDocumentsQuery.data),
      areCommandsLoaded: Boolean(queries.fetchCommands.data),
      isUpdating,
      isSubmitButtonDisabled:
        !localState.rules.every(helpers.isRuleCompleted) || !localState.rules.length || isUpdating,
      availableBulkReprocessActions: helpers.getAvailableActionsMap(localState.rules)
    };
  }, [
    mutations.updateDocumentDataMutation.isLoading,
    props.selectedDocumentIds.length,
    localState.rules,
    queries.getSelectedDocumentsQuery.data,
    queries.fetchCommands.data
  ]);
};
