import { DOC_STATUS_COLORS } from '../../../constants/document';
import { DOCUMENT_PIPELINE_STATUSES as DOC_STAT } from '../../../constants/docPipelineStatuses';

// Errors
export const ERRORS = {
  pubDate: 'Publication date should not be in future.'
};

// Statuses that should be edited
export const EDIT_STATUSES = [
  DOC_STAT.FLAGGED.type,
  DOC_STAT.HIDDEN.type,
  DOC_STAT.DUPLICATED.type,
  DOC_STAT.POTENTIAL_DUPLICATE.type,
  DOC_STAT.INCOMPLETE.type,
  DOC_STAT.HOLD.type,
  DOC_STAT.FUTURE_PUB_DATE.type
];

// Possible actions on docs
export const DOC_ACTIONS = {
  FLAG: { type: DOC_STAT.FLAGGED.type, label: 'Flag' },
  UNFLAG: { type: DOC_STAT.CLASSIFIED.type, label: 'Un-flag' },
  HIDE: { type: DOC_STAT.HIDDEN.type, label: 'Hide' },
  UNHIDE: { type: DOC_STAT.CLASSIFIED.type, label: 'Un-hide' },
  SET_DUPLICATE: { type: DOC_STAT.DUPLICATED.type, label: 'Duplicate' },
  SET_NOT_DUPLICATE: { type: DOC_STAT.ORIGINAL.type, label: 'Not duplicate' },
  SET_POTENTIAL_DUPLICATE: {
    type: DOC_STAT.POTENTIAL_DUPLICATE.type,
    label: 'Potential duplicate'
  },
  SET_DEPRECATED: { type: DOC_STAT.DEPRECATED.type, label: 'Deprecate' }
};

export const STATUSES_TREE = {
  [DOC_STAT.INCOMPLETE.type]: [DOC_STAT.RESCRAPED.type],
  [DOC_STAT.HOLD.type]: [DOC_STAT.NEW, DOC_STAT.INCOMPLETE.type],
  [DOC_STAT.FLAGGED.type]: [DOC_STAT.RESCRAPED.type],
  [DOC_STAT.HIDDEN.type]: [DOC_STAT.RESCRAPED.type],
  [DOC_STAT.FUTURE_PUB_DATE.type]: [DOC_STAT.CLASSIFIED.type],
  [DOC_STAT.DUPLICATED.type]: [DOC_STAT.ORIGINAL.type],
  [DOC_STAT.POTENTIAL_DUPLICATE.type]: [DOC_STAT.ORIGINAL.type],
  [DOC_STAT.PROCESSED.type]: [
    DOC_STAT.INCOMPLETE.type,
    DOC_STAT.FLAGGED.type,
    DOC_STAT.HIDDEN.type,
    DOC_STAT.FUTURE_PUB_DATE.type,
    DOC_STAT.DUPLICATED.type
  ]
};

export const DOC_ACTIONS_TREE = {
  [DOC_STAT.HIDDEN.type]: [
    DOC_ACTIONS.UNHIDE,
    DOC_ACTIONS.FLAG,
    DOC_ACTIONS.SET_DEPRECATED,
    DOC_ACTIONS.SET_DUPLICATE,
    DOC_ACTIONS.SET_POTENTIAL_DUPLICATE
  ],
  [DOC_STAT.DEPRECATED.type]: [],
  [DOC_STAT.DUPLICATED.type]: [DOC_ACTIONS.SET_DEPRECATED, DOC_ACTIONS.SET_NOT_DUPLICATE],
  [DOC_STAT.POTENTIAL_DUPLICATE.type]: [
    DOC_ACTIONS.SET_DEPRECATED,
    DOC_ACTIONS.SET_DUPLICATE,
    DOC_ACTIONS.SET_NOT_DUPLICATE
  ],
  [DOC_STAT.FLAGGED.type]: [
    DOC_ACTIONS.UNFLAG,
    DOC_ACTIONS.HIDE,
    DOC_ACTIONS.SET_DEPRECATED,
    DOC_ACTIONS.SET_DUPLICATE,
    DOC_ACTIONS.SET_POTENTIAL_DUPLICATE
  ],
  [DOC_STAT.PROCESSED.type]: [
    DOC_ACTIONS.FLAG,
    DOC_ACTIONS.HIDE,
    DOC_ACTIONS.SET_DEPRECATED,
    DOC_ACTIONS.SET_DUPLICATE,
    DOC_ACTIONS.SET_POTENTIAL_DUPLICATE
  ]
};

export const DOCUMENT_GENERAL = {
  docID: 'id',
  createdAt: 'created_at',
  updatedAt: 'internal_updated_at',
  effectiveOn: 'effective_on',
  publicationDate: 'publication_date',
  fullText: 'full_text',
  fullXml: 'full_xml',
  summaryText: 'summary_text',
  title: 'title',
  pdfUrl: 'pdf_url',
  webUrl: 'web_url',
  metaTable: 'meta_table',
  apiTable: 'api_table',
  pipelineStatus: 'pipeline_status',
  interactionStatus: 'interaction_status',
  officialId: 'official_id',
  docketId: 'docket_id',
  parentId: 'parent_id',
  parentStatus: 'parent_doc.pipeline_status',
  docScrapedCategoryId: 'doc_scraped_category_id',
  jurisdictionId: 'jurisdiction_id',
  agencies: 'agencies',
  agencyIds: 'agency_ids',
  spiderName: 'spider_name',
  pdfHash: 'pdf_hash',
  fullTextHash: 'full_text_hash',
  jurisdiction: 'jurisdiction',
  category: 'category',
  appCategory: 'app_category',
  scrapedCategory: 'scraped_category',
  scrapedCategoryName: 'scraped_category.name',
  newsSourceName: 'mainstream_news.news_source.0.name',
  newsSource: 'news_source',
  aiUrl: 'c_ai_url',
  takeAction: 'take_action',
  shelfId: 'shelf_id',
  rootShelfId: 'root_shelf_id',
  document_examination_record: 'document_examination_record',
  documentLanguages: 'document_languages',
  documentLabels: 'document_eitl_labels',
  duplicateList: 'document_relations.duplicate',
  potentialDuplicateList: 'document_relations.potential_duplicate',
  enforcementViolation: 'enforcement.violation',
  enforcementPenalty: 'enforcement.penalty',
  enforcementRespondent: 'enforcement.respondent'
};

// crawls details use by DocumentDetails component (just help to show structure)
export const DOCUMENT_DETAILS = {
  [DOCUMENT_GENERAL.docID]: '', // string (only numbers)
  [DOCUMENT_GENERAL.createdAt]: null, // date
  [DOCUMENT_GENERAL.updatedAt]: null, // date
  [DOCUMENT_GENERAL.effectiveOn]: null, // date
  [DOCUMENT_GENERAL.publicationDate]: null, // string
  [DOCUMENT_GENERAL.shelfId]: null, // number
  [DOCUMENT_GENERAL.rootShelfId]: null, // number
  [DOCUMENT_GENERAL.fullText]: null, // string
  [DOCUMENT_GENERAL.fullXml]: null, // string
  [DOCUMENT_GENERAL.summaryText]: null, // string
  [DOCUMENT_GENERAL.title]: null, // string
  [DOCUMENT_GENERAL.pdfUrl]: null, // string
  [DOCUMENT_GENERAL.webUrl]: null, // string
  [DOCUMENT_GENERAL.metaTable]: null, // string
  [DOCUMENT_GENERAL.apiTable]: null, // string
  [DOCUMENT_GENERAL.pipelineStatus]: null, // object {value: 1, label: 1}
  [DOCUMENT_GENERAL.interactionStatus]: null, // string
  [DOCUMENT_GENERAL.officialId]: null, // string (only numbers)
  [DOCUMENT_GENERAL.docketId]: null, // string (only numbers)
  [DOCUMENT_GENERAL.parentId]: null, // string (only numbers)
  [DOCUMENT_GENERAL.parentStatus]: null, // object {value: 1, label: 1}
  [DOCUMENT_GENERAL.docScrapedCategoryId]: null, // string (only numbers)
  [DOCUMENT_GENERAL.jurisdictionId]: null, // string (only numbers)
  [DOCUMENT_GENERAL.agencyIds]: [], // array of string
  [DOCUMENT_GENERAL.agencies]: [], // array of objects {value: 1, label: 1}
  [DOCUMENT_GENERAL.spiderName]: null, // string
  [DOCUMENT_GENERAL.pdfHash]: null, // string
  [DOCUMENT_GENERAL.fullTextHash]: null, // string
  [DOCUMENT_GENERAL.jurisdiction]: null, // string
  [DOCUMENT_GENERAL.scrapedCategory]: null, // string
  [DOCUMENT_GENERAL.newsSource]: null, // string
  [DOCUMENT_GENERAL.newsSourceName]: '', // string
  [DOCUMENT_GENERAL.aiUrl]: null, // string
  [DOCUMENT_GENERAL.document_examination_record]: [], // array
  [DOCUMENT_GENERAL.documentLanguages]: [], // array
  [DOCUMENT_GENERAL.documentLabels]: [], // array
  [DOCUMENT_GENERAL.duplicateList]: [], // array
  [DOCUMENT_GENERAL.potentialDuplicateList]: [], // array
  [DOCUMENT_GENERAL.enforcementViolation]: null, // string
  [DOCUMENT_GENERAL.enforcementPenalty]: null, // string
  [DOCUMENT_GENERAL.enforcementRespondent]: null // string
};

export const docStatusStyle = {
  [DOC_STATUS_COLORS.DEFAULT]: 'document-details_fields_status-select_blue',
  [DOC_STATUS_COLORS.RED]: 'document-details_fields_status-select_red',
  [DOC_STATUS_COLORS.GREEN]: 'document-details_fields_status-select_green',
  [DOC_STATUS_COLORS.ORANGE]: 'document-details_fields_status-select_yellow',
  [DOC_STATUS_COLORS.GRAY]: 'document-details_fields_status-select_gray',
  [DOC_STATUS_COLORS.LIGHT_RED]: 'document-details_fields_status-select_light-red',
  [DOC_STATUS_COLORS.PURPLE]: 'document-details_fields_status-select_purple',
  [DOC_STATUS_COLORS.BROWN]: 'document-details_fields_status-select_brown',
  [DOC_STATUS_COLORS.PINK]: 'document-details_fields_status-select_pink'
};

export const documentFlagActions = [
  DOC_ACTIONS.FLAG.type,
  DOC_ACTIONS.HIDE.type,
  DOC_ACTIONS.SET_DUPLICATE.type,
  DOC_ACTIONS.SET_POTENTIAL_DUPLICATE.type
];

export const RELATION_TYPES = {
  LANGUAGE: 'Language',
  DUPLICATE: 'Duplicate',
  POTENTIAL_DUPLICATE: 'potential_duplicate'
};
